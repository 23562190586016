
import React from "react" 
import { Helmet } from "react-helmet";       
import { Router } from "@reach/router"
import { ApolloProvider } from "@apollo/client";
import { apolloClient  } from "../client/apollo";
import { Media } from "../templates/media/Media";
 
const MediaZpravyPage = ({ location}) => {

  return (
      <>
         <Helmet>
          <meta charSet="utf-8" />
          <title>Média a zprávy | Exekutorský úřad Praha 9</title> 
        </Helmet>  

        <ApolloProvider client={apolloClient}>
            <Router>  
                <Media path="/media-zpravy" location={location} /> 
                <Media path="/media-zpravy/strana/:page" location={location} /> 
            </Router>
        </ApolloProvider>
      </>

  )
}
 
export default MediaZpravyPage
 