
import React from "react"
import Layout from "../../components/layout/Layout"
import { Loading } from "../../components/layout/Loading"
import { EmptyList } from "../../components/elements/EmptyList"
import {default as Symbol} from "../../images/symbol.png"
import { useQuery } from "@apollo/client";
import { ARTICLES_LIST } from "../../query/articles";
import ReactPaginate from 'react-paginate'; 
import { navigate } from "@reach/router";
import { Link } from "gatsby";
import { format } from 'date-fns'


export const Media = ({location}) => {

    let page = 1; 
    let offset = 0;
    let paging = 10;

    let path
    let cat
    path = location.pathname.split('/');
    if(path[2]==='strana') {
        page = parseInt(path[3])
    }
    if(path[3]==='strana') {
        page = parseInt(path[4])
    }
    console.log(page);

    if(page>1) {
        offset = (paging*page)-paging;
    }

    const handlePageClick = (e) => {
        let url = '/media-zpravy/' 
        const selectedPage = e.selected+1; 
        if(cat) {
            url = url + cat + '/'
        }
        if(selectedPage===1) {
            navigate(url + location.search)
        } else {
            navigate(url + 'strana/' + selectedPage + '/' + location.search)
        }
         
    }; 

    let queryName = ARTICLES_LIST;
    const {loading, data} = useQuery(queryName, {
        variables: {
            cat: 'media-zpravy',
            limit: paging,
            offset: offset
        }
    });

    let items = data?.articleFindAll || [];
    let pageCount = Math.ceil(data?.articleFindAllCount/paging) ?? 0;

    const Clanek = ({item}) => {
        let img
        if(item?.img) {
            img = item.img
        }
        let datum = format(new Date(item.issueDate),'dd. MM. yyyy')

        return (
            <div>   
                
                <section className="max-w-5xl mx-auto" >
                
                  <div className="rounded-lg bg-white overflow-hidden shadow"> 
                    <div className="bg-gray-100 p-6">
                        <div className="grid lg:grid-cols-3 gap-6">
                            <div className="flex-shrink-0 max-w-sm mx-auto">
                                <Link to={'/media/' + item.alias + '/'}> 
                                <div className="relative w-full sm:min-h-56 lg:min-h-56 lg:h-56 rounded-md overflow-hidden ">
                                    <div className="w-full h-full aspect-w-1 aspect-h-1 object-center  lg:aspect-none">
                                        <div className="w-full h-full  object-center object-cover " >
                                            {img ?
                                                <img className="mx-auto object-cover" src={img} alt="" />
                                                : 
                                                <img className="object-cover h-36 mx-auto " alt="" src={Symbol} />
                                            }
                                        </div>
                                        
                                    </div>
                                </div>
                                </Link>
                            </div>
                            <div className="lg:col-span-2">
                                <p className="text-lg text-gray-900 sm:text-xl font-bold"><Link to={'/media/' + item.alias + '/'}>{item.name}</Link></p>
                                
                                
                                <div dangerouslySetInnerHTML={{__html: item.prologue}} className="text-sm line-clamp-5">
                            
                                </div>
                                <div>
                                   
                                </div>
                                <div className=" flex justify-between mt-3">
                                    <div>
                                        <span className="text-gray-500 text-xs">{datum}</span> 
                                    </div>
                                    <Link to={'/media/' + item.alias + '/'}  className="inline-flex justify-center items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">
                                       Celý článek
                                    </Link>
                                </div>
                            </div>
                            
                        </div>
                    </div> 
                  </div>
                </section>
            </div>
        )
    }
    

    return(
        <Layout location={location} pageContext={{title: 'Média a zprávy' }}>
            <div>
                
                {items && items.length === 0 && loading &&  <Loading loading={loading} />} 
                {items && items.length === 0 && !loading &&  
                    <>  
                        
                         <EmptyList text={'Žádné položky'} /> 
                        
                    </>
                    
                }
                    
                    
                <div className="mt-6 grid  gap-y-8 gap-x-6   xl:gap-x-8 max-w-7xl mx-auto  px-5">
                    
                    {items && items.length > 0 && items.map((item,itemIdx) => (
                        <Clanek key={itemIdx} item={item} />
                    ))}

                </div>
                
            </div>

            {!loading && pageCount > 1 &&  
            <div className="px-4 py-10 grid items-center justify-items-center mt-3  sm:px-6">
                <ReactPaginate  
                    previousLabel={
                        <>
                        
                            <>
                            <svg className="h-5 w-5 inline" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                <path fillRule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clipRule="evenodd" />
                            </svg>
                            <span className="inline-flex sm:hidden">Předchozí</span>
                            </>
                       
                        </>
                    }
                    nextLabel={
                        <>
                        <span className="inline-flex sm:hidden">Následující</span>
                        <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                            <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
                        </svg>
                        </>
                    }
                    previousClassName={"relative rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:outline-none flex-1 flex justify-between " +  (page === 1 && "hidden") }
                    previousLinkClassName={" px-2 py-2  focus:outline-none  inline-flex items-center"}
                    nextClassName={"relative  rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:outline-none " 
                    +  (page === 1 && "rounded-l-md lg:rounded-l-none ") 
                    +  (page === pageCount && " hidden") }
                    nextLinkClassName={"px-2 py-2 focus:outline-none inline-flex items-center"}
                    breakLabel={"..."}
                    breakClassName={"relative  sm:inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none"}
                    pageCount={pageCount} 
                    forcePage={page - 1}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={handlePageClick}
                    containerClassName={"relative z-0 inline-flex  rounded-md shadow-sm -space-x-px select-none "}
                    subContainerClassName={""}
                    pageClassName={"hidden sm:inline-flex relative    border border-gray-300 bg-white text-sm font-medium  hover:bg-gray-50 " }
                    pageLinkClassName={"px-4 py-2 text-gray-700 focus:outline-none"}
                    activeClassName={"bg-amber-200 hover:bg-amber-300 "}
                    />
                </div>  
            }
        </Layout>
    )
}